.App {
  text-align: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  background-color: #030015;
  color: white;
  min-height: 100vh;
}

.Gallery {
  display: block;
  margin: 10px;
}

.FilterPanel {
  padding: 1em;
}

.Footer {
  color:darkslategray;
}
.GalleryPage p {
  margin: 0px 10px;
}

.Description {
  font-style: italic;
  font-size: smaller;
}

.image-gallery-image {
  height: 60vh !important;
  max-height: 800px !important; 
}
@media(max-height:1080px) {
  .image-gallery-image {
    height: 55vh !important;
    max-height: 800px !important; 
  }
}

.Contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  padding:5px;
}

.Contact-form .btn {
  margin: 5px
}
.Contact-buttons .btn {
  margin: 5px;
  min-width: 12em;
}

.clickable {
  cursor: pointer;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h4 a {
  color: rgb(200, 200, 200);;
  text-decoration: underline;
}


